<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet class="vh-100"></ion-router-outlet>
      <cart-footer v-if="cart" :carts="carts"></cart-footer>
      <ion-tab-bar class="tab-custom" slot="bottom" v-else>
        <ion-tab-button tab="home" href="/home">
          <i class="fa-solid fa-home fa-2x fa-fw"></i>
          <ion-label>{{ $t("tabs.home") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="myLottery" href="/myLottery">
          <i class="fa-solid fa-ticket fa-2x fa-fw"></i>
          <ion-label>{{ $t("tabs.myLottery") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="event" href="/event">
          <i class="fa-solid fa-newspaper fa-2x fa-fw"></i>
          <ion-label>{{ $t("tabs.event") }}</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="profile" href="/profile">
          <i class="fa-solid fa-ellipsis fa-2x fa-fw"></i>
          <ion-label>{{ $t("tabs.more") }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {IonTabBar, IonTabButton, IonTabs, IonLabel, IonPage, IonRouterOutlet} from '@ionic/vue';
import CartFooter from "@/components/CartFooter";

export default {
  components: {
    CartFooter,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonPage,
    IonRouterOutlet
  },
  data() {
    return {
      cart: false,
      carts: []
    }
  },
  created() {
    this.emitter.on('toggle-cart', async () => {
      const carts = await this.storage.get('carts');
      this.carts = carts;
      try {
        if (carts && carts.length > 0) {
          this.cart = true;
        } else {
          this.cart = false;
        }
      } catch (e) {
        this.cart = false;
      }
    })
  },
  mounted() {
    this.emitter.emit('toggle-cart');
  }
}
</script>
<style scoped>
.tab-custom {
  height: 8vh;
//border-top-left-radius: 30% !important; //border-top-right-radius: 30% !important; background: white !important;
}

ion-tab-button {
  --color-selected: orange !important;
  --background: white !important;
}

</style>
